import { Component, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { SVGIcon, filePdfIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { SelectableSettings, SelectableMode } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { getconsumptionHistoryExportResponse, getconsumptionHistoryResponse } from '../../../store/customer/consumer-selector';
import { getConsumptionHistory, getConsumptionHistoryExport, resetConsumptionHistoryState } from '../../../store/customer/consumer.action';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { CdkStepper } from '@angular/cdk/stepper';
@Component({
  selector: 'app-consumption-history',
  templateUrl: './consumption-history.component.html',
  styleUrl: './consumption-history.component.scss'
})
export class ConsumptionHistoryComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = "row";
  projectId: any;
  consumerId: any;
  currentUser: any;
  totalMasterCount: number = 0;
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  public metergridData: GridDataResult = { data: [], total: 0 };
  public consumptionRechargesState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };


  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = ''
  meterconfigList: any[] = [];

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public pageSize = 10;
  public skip = 0;

  totalUserCount: number = 0;
  currentPage: number = 1;
  public consmptiongridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  //public gridData: unknown[] = [];


  breadCrumbItems: Array<{}> | undefined;


  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public mySelection: string[] = [];

  constructor(private modalService: BsModalService, private router: Router,
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService, private PdfexportService: PdfexportService) {
    this.breadCrumbItems = [{ label: 'Consumption History', }];
    this.getConsumptionStoreInfo()


  }
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  consumptionHisttory: any[] = []

  isAllOpen: boolean = true;

  consumtionGridView: boolean = true;
  consumtionDetailView: boolean = false;

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  public onFilter(value: Event): void {
    const inputValue = value;
    //  this.searchedValue = value;
    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );


  }

  ngAfterViewInit(): void {
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use
    }
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUser?.project.id;
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }
  private exportSubscription: Subscription = new Subscription;
  exportData: any[] = []
  getConsumptionStoreInfo() {
    this.store.dispatch(resetConsumptionHistoryState());
    let getConsumptionHistory$ = this.store.pipe(select(getconsumptionHistoryResponse));
    this.exportSubscription = this.store.pipe(select(getconsumptionHistoryExportResponse)).subscribe((res: any) => {
      if (res) {
        this.exportData = []
        if (res?.items.length > 0) {
          this.exportData = [...res.items];
          const transformedData = this.exportData?.map((item: any) => ({
            utility: item.utility,
            MeterNo: item.macAddress,
            unitRate: item.unitRate,
            Consumptionunits: item.consumptionUnits,
            PaymentRefNo: item.unitRate,
            AmountDeducted: item.amountDeducted,
            AdditionalCharges: item.additionalCharges,
            AvailableBalance: item.availableBalance,

          }));
          this.PdfexportService.downloadExcel(transformedData, 'ConsumptionHistory');
        } else {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );

        }
      }
    });
    getConsumptionHistory$.subscribe((res: any) => {
      if (res) {
        this.consumptionHisttory = [...res.items];
        console.log('res', res);
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.loadGridTable();
      }
    })


  }
  exportAll() {
    this.store.dispatch(getConsumptionHistoryExport({ ProjectId: this.projectId, criteria: 'All', }));
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();  // Or set it to [] if you prefer an empty array
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  public onExport(type: string) {
    /* if (this.selectedRowdata.length == 0) {
      this.store.dispatch(getConsumptionHistoryExport({ ProjectId: this.projectId, criteria: 'All', }));
    } */
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.consumptionHisttory, state);
    this.consmptiongridData = {
      data: this.consumptionHisttory,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }


  private loadMterConfig(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.meterconfigList, state);

    this.metergridData = {
      data: processedData.data,
      total: this.totalMasterCount,
    };
    this.metergridData.data = this.metergridData.data.map((meter) => {
      console.log(new Date(meter.currentDate));
      return {
        ...meter,
        currentDate: new Date(meter.currentDate), // Modify the billDate property
      };
    });
  }

  viewconsumption(dataItem: any) {
    if (this.stepper) {
      localStorage.setItem('selectedTabIndex', this.stepper.selectedIndex.toString());
    }
    this.router.navigate(['/consumptionHistory/consumptionView'], { state: { consumption: dataItem } })

  }
  backConsumption(event: any) {
    this.consumtionGridView = true;
    this.consumtionDetailView = false;
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.consumptionRechargesState.sort = sort;
    console.log(this.consumptionRechargesState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.consumptionRechargesState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  protected consumptionPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = []
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    this.loadGridTable();
  }

  protected consumptionStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  public onprojectFilterChange(filter: CompositeFilterDescriptor): void {
    this.consumptionRechargesState.filter = filter;
    console.log(this.consumptionRechargesState);
    const queryString = this.sharedService.createQuery(this.consumptionRechargesState);
    this.queryString = this.sharedService.createQuery(this.consumptionRechargesState);
    this.store.dispatch(
      getConsumptionHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    console.log(queryString);
  }


  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }





























}

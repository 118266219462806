<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    @if(cdkStepper.selectedIndex === 0){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    @if(cdkStepper.selectedIndex === 1){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    <!-- <button class="btn btn-light d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button> -->

                </span>

            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div id="basic-example">
                        <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper">

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">1.</span>
                                    <span>Consumption History</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 0){
                                <section>
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <div *ngIf="consumtionGridView">
                                            <kendo-grid [data]="consmptiongridData" [reorderable]="true"
                                                filterable="menu" [columnMenu]="{ filter: true }" [resizable]="true"
                                                [pageSize]="10" scrollable="scrollable" [pageable]="{
                                              buttonCount: buttonCount,
                                              info: info,
                                              type: type,
                                              pageSizes: pageSizes,
                                              previousNext: previousNext
                                            }" [selectable]="selectableSettings"
                                                [filter]="consumptionRechargesState.filter"
                                                [skip]="consumptionRechargesState.skip"
                                                [sort]="consumptionRechargesState.sort" [columnMenu]="{ filter: true }"
                                                (filterChange)="onprojectFilterChange($event)"
                                                (sortChange)="onSortChange($event)" [sortable]="true" [resizable]="true"
                                                (selectionChange)="onSelect($event)"
                                                (pageChange)="consumptionPageChange($event)"
                                                (dataStateChange)="consumptionStateChange($event)">

                                                <ng-template kendoGridToolbarTemplate>
                                                    <!-- <kendo-textbox [style.width.px]="250"
                                                        placeholder="Search in all columns..."
                                                        [(ngModel)]="searchedValue"
                                                        (valueChange)="onFilter($event)"></kendo-textbox> -->
                                                    <div class="search_icon">
                                                        <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                                            [style.height.px]="35" [(ngModel)]="searchedValue"
                                                            placeholder="Search in all columns...">
                                                        </kendo-textbox>

                                                        <button class="btn btn-primary btn-md border-left-none"
                                                            (click)="onFilter($event)">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                    <kendo-grid-spacer></kendo-grid-spacer>

                                                    <button
                                                        class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                        (click)="onExport('Excel')">
                                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                    </button>

                                                    <!-- button class="btn btn-light" (click)="onExport('Pdf')">
                                                        <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                                                </ng-template>

                                                <!-- Pdf   start-->
                                                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                                    <kendo-grid
                                                        [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:exportData.length>0?exportData:consmptiongridData.data"
                                                        #pdfGrid>
                                                        <kendo-grid-column field="utility" title="Uility">
                                                        </kendo-grid-column>

                                                        <kendo-grid-column field="macAddress"
                                                            title="Meter No"></kendo-grid-column>
                                                        <kendo-grid-column field="consumptionUnits"
                                                            title="Consumption(units)"></kendo-grid-column>
                                                        <kendo-grid-column field="unitRate"
                                                            title="Unit Rate"></kendo-grid-column>

                                                        <kendo-grid-column field="amountDeducted"
                                                            title="Amount Deducted"></kendo-grid-column>
                                                        <kendo-grid-column field="additionalCharges"
                                                            title="Additional Charges"></kendo-grid-column>
                                                        <kendo-grid-column field="availableBalance"
                                                            title="Available Balance"></kendo-grid-column>
                                                        <kendo-grid-excel
                                                            fileName="ConsumptionHistory.xlsx"></kendo-grid-excel>
                                                        <kendo-grid-pdf fileName="ConsumptionHistory.pdf"
                                                            [allPages]="true" paperSize="A4"
                                                            [landscape]="true"></kendo-grid-pdf>
                                                    </kendo-grid>
                                                </div>
                                                <!-- Pdf Edrt-->

                                                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                    [width]="40"></kendo-grid-checkbox-column>
                                                <kendo-grid-column field="utility" title="Uility">
                                                    <ng-template kendoGridCellTemplate let-dataItem>

                                                        <span></span> {{ dataItem.utility =='Electricity' ?
                                                        dataItem.utility+ '('+(dataItem.isEB==true?'EB':'DG')+')'
                                                        :dataItem.utility
                                                        }}

                                                    </ng-template>
                                                </kendo-grid-column>

                                                <kendo-grid-column field="macAddress"
                                                    title="Meter No"></kendo-grid-column>
                                                <kendo-grid-column field="consumptionUnits" title="Consumption(units)">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{dataItem.utility=='Water'?dataItem.consumptionUnits+' L':
                                                        dataItem.utility=='Electricity'?
                                                        dataItem.consumptionUnits+' kWh':
                                                        dataItem.utility=='Gas'?dataItem.consumptionUnits+' m³':''
                                                        }}
                                                    </ng-template>
                                                    <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                                            {{dataItem.utility=='Water'?dataItem.consumptionUnits+' L':
                                                            dataItem.utility=='Electricity'? 'EB:'+dataItem.consumptionUnitsEB+' kWh'
                                                            +'\n'+'/'+ 'DG:'+dataItem.consumptionUnitsDG+' kWh':
                                                            dataItem.utility=='Gas'?dataItem.consumptionUnits+' m³':''
                                                            }}
                    
                    
                                                        </ng-template> -->
                                                </kendo-grid-column>
                                                <!-- <kendo-grid-column field="unitRate" title="Unit Rate"></kendo-grid-column> -->

                                                <kendo-grid-column field="unitRate" title="Unit Rate">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.unitRate | currency: 'INR':'symbol':'1.2-2' }}
                                                        <!--  {{dataItem.utility=='Water'?(dataItem.unitRate| currency:
                                                            'INR':'symbol':'1.2-2'):
                                                            dataItem.utility=='Electricity'? 'EB:'+(dataItem.unitRateEB| currency:
                                                            'INR':'symbol':'1.2-2')
                                                            +'\n'+'/'+ 'DG:'+(dataItem.unitRateDG | currency: 'INR':'symbol':'1.2-2') :
                                                            dataItem.utility=='Gas'?(dataItem.unitRate | currency: 'INR':'symbol':'1.2-2')
                                                            :''
                                                            }} -->
                                                    </ng-template>
                                                </kendo-grid-column>

                                                <kendo-grid-column field="unitPrice" title="Amount Deducted">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.amountDeducted | currency: 'INR':'symbol':'1.2-2' }}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field="availableBalance" title="Available Balance">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2'
                                                        }}
                                                    </ng-template>
                                                </kendo-grid-column>


                                                <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <div class="d-flex">
                                                            <button
                                                                class="btn btn-success btn-sm d-flex align-items-center "
                                                                tooltip="Go to Project Details"
                                                                (click)="viewconsumption(dataItem)">
                                                                <i class="fas fa-info-circle"></i>
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                </kendo-grid-command-column>
                                            </kendo-grid>
                                        </div>
                                    </div>
                                </section>
                                }
                            </cdk-step>

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">2.</span>
                                    <span>Meter Reading History </span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 1){

                                }
                            </cdk-step>

                        </ng-stepper>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>